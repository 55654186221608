import React from "react";
import { Link } from "gatsby";
import PlayIcon from "../../assets/img/svg/play-icon.svg";
import PlayIconWhite from "../../assets/img/svg/play-icon-white.svg";
import "./style.scss";

const ReadMore = ({ to, color, text, externalLink }) => {
  return (
    <>
      {externalLink ? (
        <a href={to} target="_blank" rel="noreferrer">
          <div className="readMore">
            <div className={`readMoreButton ${color ? color : "default"}`}>
              <div className="readMoreIcon">
                {color === "white" ? (
                  <img src={PlayIconWhite} alt="Play-Icon-White" />
                ) : (
                  <img src={PlayIcon} alt="Play-Icon" />
                )}
              </div>
              <div className="readMoreText">{text}</div>
            </div>
          </div>
        </a>
      ) : (
        <Link to={to}>
          <div className="readMore">
            <div className={`readMoreButton ${color ? color : "default"}`}>
              <div className="readMoreIcon">
                {color === "white" ? (
                  <img src={PlayIconWhite} alt="Play-Icon-White" />
                ) : (
                  <img src={PlayIcon} alt="Play-Icon" />
                )}
              </div>
              <div className="readMoreText">{text}</div>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default ReadMore;
