import React from "react";
import Image from "../image/image.js";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import ReadMore from "../readMore/readMore.js";
import SwiperCore, { Autoplay } from "swiper";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";

import "./style.scss";
import "swiper/swiper.scss";

const Header = ({ title, description, categoryIcon, logo, metaDescription, metaKeywords, tagTitle}) => {
  const BANNERS = [
    {
      title: "La città di Riccione è connessa",
      filename: "home/bench.png",
      alt: "Panca",
      to: "/riccione",
    },
    {
      title: "Le Befane Shopping Centre è connesso",
      filename: "home/shopper-bag.png",
      alt: "Shopper Bag",
      to: "/lebefane",
    },
  ];

  SwiperCore.use([Autoplay]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{tagTitle ? tagTitle : title} | Connect@You WiFi is our hit</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <meta property="og:title" content="Connect@You - WiFi is our hit" />
        <meta property="og:image" content="https://static.acmeproduzioni.it/connectatyou/logo.svg" />
        <meta property="og:description" content="Un moderno servizio di hotspot che permette di offrire ai tuoi clienti una connessione WiFi gratuita, facile e sicura"/>
        <link rel="canonical" href="https://connectatyou.it/sistemi-wifi"/>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      {title === "Connect@You - Sistemi di Reti Wifi ed HotSpot" ? (
        <div className="header">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            grabCursor={true}
            loop={true}
            speed={3000}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
          >
            {BANNERS.map((i, index) => (
              <SwiperSlide key={index}>
                <div className="container">
                  <div className="columns ">
                    <div className="column is-fullhd">
                      <div className="title">
                        <h1>{i.title}</h1>
                      </div>
                      <ReadMore to={i.to} color="azure" text="Scopri di più" />
                      <Image className="imgSlider" filename={i.filename} alt={i.alt} />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="header">
          <div className="section" />
          <div className="container">
            <div className="columns">
              <div className="column ">
                <div className="title">
                  <h1>{title}</h1>
                </div>
                {description && (
                  <div className="columns mt-6">
                    <div className="column is-half is-offset-one-quarter ">
                      <p className="center">
                        <br />
                        {description}
                      </p>
                    </div>
                  </div>
                )}
                {!logo && <div className="section" />}
                {categoryIcon && (
                  <Controller>
                    <Scene offset={100} duration="200%" triggerHook="onEnter">
                      <Timeline wrapper={<div className="parallax" />}>
                        <Tween
                          position="0"
                          from={{
                            top: "120%",
                            scale: 0.5,
                          }}
                          to={{
                            top: "-100%",
                            scale: 1,
                          }}
                        >
                          <img src={categoryIcon.icon} alt={categoryIcon.alt} />
                          <div id="wave1"></div>
                          <div id="wave2"></div>
                        </Tween>
                      </Timeline>
                    </Scene>
                    <div className="section" />
                  </Controller>
                )}
              </div>
            </div>
          </div>
          {logo && (<><div className="columns">
            <div className="column is-4 is-offset-4">
            <div className="section" />              
              <Image filename={logo} alt={title} />
              <div className="section" />
            </div>
          </div></>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
